import { graphql } from "gatsby";
import React, { useEffect } from "react";
import { useLocale } from "../hooks";
import Seo from "../components/seoGlobal";
//import Seo from "../components/seo";
import Schema from "../components/schema";
const Wheretobuy = ({
    location,
    pageContext,
    data: {
        contentfulPageCustom: {
            seoTitle,
            seoDescription,
        },
        allContentfulPageCustom: {
            nodes: allLinks
        }
    }
}) => {
    const lang = useLocale()
    useEffect(() =>{
        const script = document.createElement('script')
        script.innerHTML = ` if(PriceSpider) PriceSpider.rebind();`
        document.body.appendChild(script)
        return () =>{document.body.removeChild(script)}
    })
    return (
        <div id="wheretobuy">
            <Seo title={seoTitle} description={seoDescription} lang={lang} hrefLangs={allLinks} wtb/>
            <div style={{margin: '7rem auto', minHeight:'70vh'}}>
                <div className="ps-widget" ps-sku="041000002878"></div>
            </div>
        </div>
    )
}

export const pageWheretobuy = graphql`
    query pageWheretobuy($id: String!, $contentful_id: String!){
        contentfulPageCustom(id: { eq: $id }) {
            slug
            seoTitle
            seoDescription {
                seoDescription
            }
        }
        allContentfulPageCustom(filter: {contentful_id: {eq: $contentful_id}, id: {ne: $id}, slug: {ne: null}}){
            nodes {
                slug
                node_locale
            }
        }
    }
`

export default Wheretobuy